import React from 'react';
import {
  PageHero,
  GoodBad,
  GoodBadItem,
  List,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/words.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Labels & Non-Label Text" subnav="words">
      <PageHero heroData={pageHeroData} tierTwo="Content Elements" tierThree="Labels & Non-Label Text" />

      <img src={require('../../../../imgs/diagrams/microcopy-taxonomy.png')} className="uni-margin--two--btm" />
      <Section />
      <Section title="UI Labels">
        <Paragraph>
          UI labels are the short bits of text that adorn most interactive elements in a user interface. They must use
          <strong> title case without punctuation</strong> unless noted otherwise.
        </Paragraph>
        <SectionSubhead> Buttons & Action Links</SectionSubhead>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Send this playlist.</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Send This Playlist</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Use title case.</li>
          <li>Don’t punctuate.</li>
        </List>

        <SectionSubhead> Form Field Labels</SectionSubhead>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              First name
              <br />
              <br />
              Please Enter Your First Name
              <br />
              <br />
              What is your name?
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>First Name</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Use title case.</li>
          <li>Avoid complete sentences (especially questions).</li>
          <li>Stick to the thing they’re entering, 1-3 words.</li>
        </List>

        <SectionSubhead> Page, Modal and Screen Titles</SectionSubhead>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Want to register your team with this organization?
              <br />
              <br />
              Offensive playbook
              <br />
              <br />
              Create a new team
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              Register Your Team with This Organization
              <br />
              <br />
              Offensive Playbook
              <br />
              <br />
              Create a New Team
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Use title case.</li>
          <li>Front-load keywords.</li>
        </List>

        <SectionSubhead> Navigation Labels</SectionSubhead>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              videos
              <br />
              <br />
              YOUR CLIPS
              <br />
              <br />
              Change report settings.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              Video
              <br />
              <br />
              Your Clips
              <br />
              <br />
              Report Settings
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Use title case.</li>
          <li>
            Caps lock is <em>forbidden</em>. (Okay in the case of style override.)
          </li>
          <li>Avoid complete sentences in navigation.</li>
        </List>
      </Section>

      <Section title="Non-Label UI Text">
        <Paragraph>
          This text is generally longer than label text, but still requires a balance of brevity and clarity.
        </Paragraph>
        <List type="unordered">
          <li>Non-label UI text should use sentence case.</li>
          <li>
            If the text is a complete sentence, use proper punctuation (unless it’s a placeholder or subject line).
          </li>
          <li>Leverage inline links as a tool to provide outs and next steps, especially after errors.</li>
        </List>

        <SectionSubhead> Non-Essential Form Text</SectionSubhead>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Custom Domains Make Links More Personal</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>A custom domain will make the links you share more personal.</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Use sentence case.</li>
        </List>

        <SectionSubhead> Success, Warning and Error Messages</SectionSubhead>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              You Added 10 Clips
              <br />
              <br />
              Clip added.
              <br />
              <br />
              Couldn’t find video to import!
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              You added 10 clips to your playlist.
              <br />
              <br />
              Sorry, we couldn’t find any video to import. Want to search again?
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Use sentence case.</li>
          <li>Don’t punctuate fragments.</li>
          <li>Avoid exclamations.</li>
          <li>Offer a next step when appropriate.</li>
        </List>

        <SectionSubhead> Empty States</SectionSubhead>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Welcome to the Video Upload page, the premier place where videos are uploaded on Hudl.com. Our speeds are
              second to none. To get started, you will first want to establish the location of your existing videos.
              <br />
              <br />
              No Video
              <br />
              <br />
              Reports not available
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              To begin, find existing videos on your device and drag them here.
              <br />
              <br />
              You don’t have any videos to share—yet. Be the first to upload.
              <br />
              <br />
              You’ll need stats to create reports. Tag a game to get started.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Be clear and direct with a complete sentence, sentence case and proper punctuation.</li>
          <li>
            Tell them what <em>should</em> be there and why.
          </li>
          <li>Keep things positive.</li>
          <li>Provide simple onboarding to help them add the right items.</li>
        </List>

        <SectionSubhead> Tooltips</SectionSubhead>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>FF 5s</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Fast-forward 5 seconds</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Fragments are allowed (no punctuation).</li>
          <li>This is a great example of clarity over cleverness.</li>
        </List>

        <SectionSubhead> Product Tours & Onboarding</SectionSubhead>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>STEP 1: ADD VIDEOS</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Step 1: Add some video.</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Use sentence case.</li>
          <li>Caps lock is still forbidden.</li>
        </List>

        <SectionSubhead> Inline Links</SectionSubhead>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>Would You Like to Add Last Season’s Video?</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Would you like to add last season’s video?</Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Use sentence case.</li>
        </List>

        <SectionSubhead> Placeholders</SectionSubhead>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Find Your Favorite Athletes...
              <br />
              <br />
              State
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              Search for schools, teams and athletes
              <br />
              <br />
              Select your state
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>Use sentence case.</li>
          <li>Don’t replace or repeat the label.</li>
          <li>
            The placeholder should always be a call-to-action (CTA), unless the field is <em>search with a label</em>.
          </li>
        </List>

        <SectionSubhead>More about Placeholders</SectionSubhead>
        <List type="unordered">
          <li>
            Placeholders require extra attention given their relationship to UI labels and the fact that they’re
            replaced by user content as the user types.
          </li>
          <li>
            With the exception of a placeholder used to provide example inputs,{' '}
            <strong>every placeholder should be a CTA</strong> written as a complete sentence (sentence case).
          </li>
          <li>
            Remember to <em>not</em> punctuate—that includes ellipses.
          </li>
        </List>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
